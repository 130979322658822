import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { ConfigService } from 'app/services/config.service';
import { ThemeIconService } from 'app/services/theme-icon.service';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import { isScullyRunning } from '@scullyio/ng-lib';
declare let APIMaticDevPortal: any;

@Component({
  selector: 'app-api-embed',
  templateUrl: './api-specification-embed.component.html',
  styleUrls: ['./api-specification-embed.component.scss'],
})
export class ApiSpecificationEmbedComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  sub: any;
  family: string;
  api: string;
  apiMaticSriptElement: HTMLScriptElement;
  portalScriptElement: HTMLScriptElement;
  walkthroughScriptElement: HTMLScriptElement;
  apiMaticElement: HTMLElement;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public loaderService: LoaderService,
    public metaService: MetaService,
    private themeIconService: ThemeIconService,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    // this.renderer2.appendChild(document.head, scriptCore);
    this.sub = this.route.params.subscribe((params) => {
      this.family = params['family'];
      this.api = params['api'];

      this.configService.getConfig('apimatic-products').subscribe(
        (apiMaticProductResponse) => {
          if (apiMaticProductResponse.Items && apiMaticProductResponse.Items.length > 0) {
            const apiMaticProducts = apiMaticProductResponse.Items[0].apimatic;
            apiMaticProducts.forEach((item) => {
              if (this.router.url.includes(item.name)) {
                const secondaryNav = [
                  {
                    title: 'Documentation',
                    path: item.family ? '/apis/' + item.family + '/' + item.name : '/apis/' + item.name,
                  },
                  {
                    title: 'API Specification',
                    path: item.family
                      ? '/apis/' + item.family + '/' + item.name + '/api-specification'
                      : '/apis/' + item.name,
                  },
                ];
                const meta = {
                  versions: item.versions,
                  template: 'sdk',
                  title: item.name,
                  'secondary-nav': secondaryNav,
                };
                this.metaService.metadata.next(meta);
                this.setPortal(item);
                return;
              }
            });
          }
          // if (!this.isApiMaticSpec) {
          //   this.router.navigateByUrl('/404', { skipLocationChange: true });
          // }
        },
        (error) => {
          this.router.navigateByUrl('/404', { skipLocationChange: true });
        }
      );
    });
  }

  private setPortal(apiItem) {
    const currentTheme = this.themeIconService.getDefaultSetting();
    this.apiMaticElement = this.parent.nativeElement.querySelector('#apimatic-widget');
    this.apiMaticSriptElement = this._renderer.createElement('script');
    this.apiMaticSriptElement.src = `assets/matic/portal-script/js/portal.v7-uber.js`;
    this.apiMaticSriptElement.defer = true;
    this.apiMaticSriptElement.onload = () => {
      this.portalScriptElement = this._renderer.createElement('script');
      this.portalScriptElement.src = this.family
        ? `assets/matic/${this.family}/${this.api}/current/static/js/portal.js`
        : `assets/matic/${this.api}/current/static/js/portal.js`;
      this._renderer.appendChild(this.parent.nativeElement, this.portalScriptElement);
      this.portalScriptElement.onload = () => {
        if (APIMaticDevPortal && APIMaticDevPortal.setThemeMode) {
          APIMaticDevPortal.setThemeMode(currentTheme);
        }
        if (apiItem['walkthrough']) {
          this.walkthroughScriptElement = this._renderer.createElement('script');
          this.walkthroughScriptElement.src = this.family
            ? `assets/matic/${this.family}/${this.api}/current/static/walkthroughs/walkthrough.js`
            : `assets/matic/${this.api}/current/static/walkthroughs/walkthrough.js`;
          this.walkthroughScriptElement.defer = true;
          this._renderer.appendChild(this.parent.nativeElement, this.walkthroughScriptElement);
        }
        this.loaderService.loadingContent.emit(false);
      };
      this.loaderService.loadingContent.emit(false);
    };
    this._renderer.appendChild(this.parent.nativeElement, this.apiMaticSriptElement);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.apiMaticSriptElement.remove();
    if (this.portalScriptElement) {
      this.portalScriptElement.remove();
    }
    if (this.walkthroughScriptElement) {
      this.walkthroughScriptElement.remove();
    }
    if (this.apiMaticElement) {
      this.apiMaticElement.innerHTML = '';
    }
  }
}
