<div *ngIf="metadata && metadata['social-network-share'] && metadata['social-network-share'].length > 0 ">
  <social-network-share [sharePlatforms]="metadata['social-network-share']"></social-network-share>
</div>
<app-back-to-top></app-back-to-top>
<div *ngIf="mode=='primary'" class="footer -primary">
  <div class="-card">
    <h1 class="-fontDisplaySourceSan">
      Still can’t find
      what you’re looking for?
    </h1>
  </div>
  <div class="-card -contact">
    <h4>Let’s start a conversation</h4>
    <h3 class="-fontDisplayClanOffcProUltra"></h3>
    <p>
      Contact us to learn more or discuss how our solutions can help your business grow.
    </p>
    <div class="-channelContainer d-md-flex flex-wrap">
      <div class="-channel">
        <div class="-link-icon-wrapper ">
          <app-link [to]="'/contact-sales'">Contact sales</app-link>
        </div>
      </div>
    </div>
  </div>
  <div class="-card -support">
    <h4>Need support?</h4>
    <div class="-button-group">
      <div class="-link-icon-wrapper -started-btn">
        <wpbutton class="-secondary" to="https://www.worldpay.com/en-gb/resources/merchant-support/support-guides">Get Support
        </wpbutton>
      </div>
      <div class="footer -stay-connected">
        <h4>Stay connected</h4>
        <div *ngIf="socialMenu" class="-social">
          <a *ngFor="let item of socialMenu" href="{{ item.path }}" target="_blank"
            rel="noopener noreferrer" aria-label="SocialMedia">
            <button-icon icon="{{item.icon}}"></button-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mode=='secondary'" class="footer -secondary">
  <div class="-secondary-container">
    <div class="-card -contact-bottom-card1">
      <div class="-channel">
        <div class="-link-icon-wrapper">
          <app-link [to]="'/contact-sales'">Contact sales</app-link>
        </div>
      </div>
    </div>
    <div class="-card -contact-bottom">
      <div class="-button-group">
        <div class="-link-icon-wrapper -started-btn">
          <wpbutton class="-secondary" to="https://www.worldpay.com/en-gb/resources/merchant-support/support-guides">Get Support
          </wpbutton>
        </div>
      </div>
    </div>
    <div class="-card -support">
      <div>
        <h4>Stay connected</h4>
        <div *ngIf="socialMenu" class="-social">
          <a *ngFor="let item of socialMenu" href="{{ item.path }}" target="_blank"
            rel="noopener noreferrer" aria-label="SocialMedia">
            <button-icon icon="{{item.icon}}"></button-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!contactFooter" class="footer -container">
  <div class="-copyright">
    <p>©{{ date }} Worldpay, LLC and/or its affiliates. All rights reserved.</p>
    <ul>
      <li>
        <app-link [to]="'https://www.worldpay.com/en'">Worldpay</app-link>
      </li>
      <li>
        <app-link [to]="'/glossary'">Glossary</app-link>
      </li>
      <li>
        <app-link [to]="'/terms-of-service'">Terms of Service</app-link>
      </li>
      <li>
        <app-link [to]="'https://www.worldpay.com/en/privacy'">Privacy notice</app-link>
      </li>
      <li>
        <app-link [to]="'https://www.worldpay.com/en/cookies'">Cookies</app-link>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="contactFooter" class="-contact-container">
  <div class="footer -contact-primary" [ngClass]="mode =='contact' ? '-bottom-offset': mode =='contact-no-offset' ? '-no-bottom-offset' : ''">
    <masthead-basic-updated img="/assets/images/hero/new-to-wp.png">
      <h4 class="-fontDisplayMedium">
        {{contactFooter.title}}
      </h4>
      <p>
        {{contactFooter.desc}}
      </p>
      <wpbutton [to]="contactFooter.path">Contact Sales</wpbutton>
    </masthead-basic-updated>
    <div class="footer -container">
      <div class="-copyright">
        <p>©{{ date }} Worldpay, LLC and/or its affiliates. All rights reserved.</p>
        <ul>
          <li>
            <app-link [to]="'https://www.worldpay.com/en'">Worldpay</app-link>
          </li>
          <li>
            <app-link [to]="'/glossary'">Glossary</app-link>
          </li>
          <li>
            <app-link [to]="'/terms-of-service'">Terms of Service</app-link>
          </li>
          <li>
            <app-link [to]="'https://www.worldpay.com/en/privacy'">Privacy notice</app-link>
          </li>
          <li>
            <app-link [to]="'https://www.worldpay.com/en/cookies'">Cookies</app-link>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
