<div class="-homepage-container"
[ngClass]="meta.title === 'Platforms' 
? '-platforms' : meta.title === 'Enterprise' 
? '-enterprise' : meta.title === 'Smb' 
? '-smb' : meta.title === 'Payfac' 
? '-payfac' : meta.title === 'Financial Institution' 
? '-financial-institution' : meta.title === 'Independent Sales Organization' 
? '-independent-sales-organization' : meta.title === 'Independent Software Vendor' 
? '-independent-software-vendor' : ''">
  <content-viewer 
  *ngIf="body" 
  [content]="body" 
  (docRendered)="handleDocRendered($event)">
  </content-viewer>
</div>
