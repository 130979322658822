import { ConfigService } from '../../services/config.service';
import { Component, OnInit, QueryList, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { BaseComponent } from '../../templates/base/base.component';
import { FragmentService } from 'app/services/fragment.service';
import { LoaderService } from 'app/services/loader.service';
import { MetaService } from 'app/services/meta.service';
import { BreakpointService } from 'app/modules/breakpoint/breakpoint.service';
import { MultiSelectDropdownComponent } from 'app/components/_common/multi-select-dropdown/multi-select-dropdown.component';
@Component({
  selector: 'apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.scss'],
})
export class APIsPageComponent extends BaseComponent implements OnInit {
  filters: any;
  resetFilters: any;
  products: any;
  originalProducts: any;
  mappings: any;
  sortOption = 'Popularity';
  selectedFilters = [];
  filterTitle = 'All APIs';
  noResults = false;
  lastSelectedCapability;
  originalCapabilityCount = [];
  originalBusinessCount = [];
  originalRegionCount = [];
  capabilityCount = [];
  businessTypeCount = [];
  regionCount = [];
  isCountEnabled = true;
  isCapabilityFilterRequest = false;
  sortOptions: any = ['Popularity', 'API Name (A-Z)', 'API Name (Z-A)'];
  @ViewChildren('multiFilters') multiFiltersComps: QueryList<MultiSelectDropdownComponent>;
  @ViewChild('multiFilterModal', { read: ElementRef }) multiFilterModal: ElementRef<HTMLElement>;
  pagerActivationThreshold = 12;
  resultsPerPage = 12;
  maxPagerItemsVisible = 5;
  currentPageIndex = 0;
  pagerStartIndex = 0;
  pagerEndIndex: number;
  paginationActive = true;
  paginatedProductsList: any;
  pageRefresher = false;

  constructor(
    public fragmentService: FragmentService,
    public configService: ConfigService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private breakpointService: BreakpointService
  ) {
    super(fragmentService, loaderService, metaService);
  }

  ngOnInit() {
    const data = { 'social-network-share': ['facebook', 'linkedin', 'twitter', 'email'] };
    this.metaService.setTitle(['APIs']);

    this.metaService.metadata.next(data);
    this.configService.getConfig('api-filters').subscribe(async (solutionFilters) => {
      this.filters = await JSON.parse(JSON.stringify(solutionFilters.Items[0].filters));
      this.resetFilters = JSON.parse(JSON.stringify(solutionFilters.Items[0].filters));
      this.filters[0]['options'].forEach((capability, index) => {
        const countCapability = {
          name: capability,
          count: 0,
        };
        this.capabilityCount.push(countCapability);
      });
      this.filters[1]['options'].forEach((business, index) => {
        const countBusinessType = {
          name: business,
          count: 0,
        };
        this.businessTypeCount.push(countBusinessType);
      });
      this.filters[2]['options'].forEach((region, index) => {
        const countRegion = {
          name: region,
          count: 0,
        };
        this.regionCount.push(countRegion);
      });

      this.configService.getConfig('api-products').subscribe((solutionProducts) => {
        this.products = solutionProducts.Items[0].products;
        this.originalProducts = solutionProducts.Items[0].products;
        this.sortProducts(null);
        this.processFilterCount(true);
        this.getPaginationIndexes(this.currentPageIndex);
        this.loaderService.loadingContent.emit(false);
        this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
          if (['xs', 'sm', 'md', undefined].indexOf(breakpoint) > -1) {
            this.multiFilterModal.nativeElement.style.cssText = 'display: none;';
          } else {
            this.multiFilterModal.nativeElement.style.cssText = 'display: none;';
          }
        });
      });
    });
  }

  applyCapabilityFilter(filter: any) {
    if (this.lastSelectedCapability && this.selectedFilters.indexOf(this.lastSelectedCapability) !== -1) {
      this.selectedFilters.splice(this.selectedFilters.indexOf(this.lastSelectedCapability), 1);
    }
    this.filterTitle = `${filter} APIs`;
    this.isCapabilityFilterRequest = true;
    this.applyFilter(filter);
    this.lastSelectedCapability = filter;
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }

  applyMultiFilter(filterObject: any) {
    this.isCapabilityFilterRequest = false;
    this.applyFilter(filterObject.name);
    this.checkUnCheckMultiFilter(filterObject);
  }

  applyFilter(filter: any, defaultCapability?: boolean) {
    if (this.selectedFilters.indexOf(filter) === -1) {
      this.selectedFilters.push(filter);
    } else {
      this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);

      // if capability filter is de selected through pill we add default 'All' capability
      if (defaultCapability) {
        this.selectedFilters.push(this.capabilityCount[0].name);
        this.lastSelectedCapability = this.capabilityCount[0].name;
        this.filterTitle = `${this.capabilityCount[0].name} APIs`;
      }
    }
    this.processFilter();
  }

  removeFilter(filter: any) {
    this.isCapabilityFilterRequest = false;
    const capability = this.capabilityCount.find((capability) => capability.name === filter);
    if (capability) {
      this.applyFilter(filter, true);
    } else {
      this.applyFilter(filter);
      this.checkUnCheckMultiFilter({ name: filter, checked: false });
    }
  }

  checkUnCheckMultiFilter(filterObject: any) {
    for (const comp of this.multiFiltersComps) {
      if (filterObject) {
        if (filterObject.checked) {
          comp.selectOption(filterObject.name);
        } else {
          comp.deselectOption(filterObject.name);
        }
      } else {
        comp.deselectAllOption();
      }
    }
  }

  reset() {
    // this.loaderService.loadingContent.emit(true);
    this.filters = [];
    this.selectedFilters = [];

    setTimeout(() => {
      this.filters = Object.assign([], this.resetFilters);
    }, 1);

    // Return original products to page
    this.products = this.originalProducts;
    this.capabilityCount = JSON.parse(JSON.stringify(this.originalCapabilityCount));
    this.businessTypeCount = JSON.parse(JSON.stringify(this.originalBusinessCount));
    this.regionCount = JSON.parse(JSON.stringify(this.originalRegionCount));
    this.checkUnCheckMultiFilter(null);
    this.getPaginationIndexes();

    // Give the page some time to refresh
    // setTimeout(() => {
    //   this.loaderService.loadingContent.emit(false);
    // }, 100);

    // Reset some variables controlling page action
    this.noResults = false;
    this.filterTitle = 'All APIs';
  }

  processFilter() {
    const filteredProducts = [];
    let product: any;

    this.originalProducts.map((x) => {
      product = Object.assign({}, x);
      product.apis = [];
      if (this.selectedFilters.every((i) => x.filters.includes(i))) {
        product.apis.push(x);
      }

      if (product.apis.length > 0) {
        filteredProducts.push(product);
      }
    });
    this.products = filteredProducts;
    this.pageRefresher = !this.pageRefresher;

    // reset all the counts before doing new count
    if (!this.isCapabilityFilterRequest) {
      this.capabilityCount = this.capabilityCount.map((capability) => {
        capability.count = 0;
        return capability;
      });
    }

    this.businessTypeCount = this.businessTypeCount.map((business) => {
      business.count = 0;
      return business;
    });
    this.regionCount = this.regionCount.map((region) => {
      region.count = 0;
      return region;
    });
    this.sortProducts(this.sortOption);
    this.processFilterCount();
    this.getPaginationIndexes();

    if (this.selectedFilters.length === 0) {
      this.filterTitle = 'All APIs';
    }

    if (this.products.length === 0) {
      this.noResults = true;
    } else {
      this.noResults = false;
    }
  }

  sortProducts(sortOption) {
    this.sortOption = sortOption;
    if (sortOption === 'API Name (A-Z)') {
      this.products.sort((a, b) => a.productTitle.toLowerCase().localeCompare(b.productTitle.toLowerCase()));
    }
    if (sortOption === 'API Name (Z-A)') {
      this.products.sort((a, b) => b.productTitle.toLowerCase().localeCompare(a.productTitle.toLowerCase()));
    }
    if (sortOption === 'Popularity' || !sortOption) {
      this.products.sort((a, b) => (a.popularity - b.popularity) || (a.productTitle.toLowerCase().localeCompare(b.productTitle.toLowerCase())));
    }
    this.getPaginationIndexes(this.currentPageIndex);
  }

  processFilterCount(isInitial?: boolean) {
    if (this.isCountEnabled) {
      this.products.forEach((product) => {
        if (!this.isCapabilityFilterRequest) {
          this.capabilityCount.forEach((capability, index) => {
            if (product.filters.includes(capability.name)) {
              capability.count++;
            }
          });
        }

        this.regionCount.forEach((region, index) => {
          if (product.filters.includes(region.name)) {
            region.count++;
          }
        });

        this.businessTypeCount.forEach((business, index) => {
          if (product.filters.includes(business.name)) {
            business.count++;
          }
        });
      });

      if (isInitial) {
        this.originalCapabilityCount = JSON.parse(JSON.stringify(this.capabilityCount));
        this.originalBusinessCount = JSON.parse(JSON.stringify(this.businessTypeCount));
        this.originalRegionCount = JSON.parse(JSON.stringify(this.regionCount));
      }
    }
  }

  closeFilter() {
    this.multiFilterModal.nativeElement.style.cssText = 'display: none;';
  }

  showFilters() {
    this.multiFilterModal.nativeElement.style.cssText = 'display: flex;';
  }
  getPaginationIndexes(pageIndex?) {
    this.currentPageIndex = pageIndex || 0;
    this.pagerStartIndex = this.currentPageIndex * this.resultsPerPage;
    this.pagerEndIndex = (this.currentPageIndex + 1) * this.resultsPerPage - 1;
    this.paginatedProductsList = this.products.slice(this.pagerStartIndex, this.pagerEndIndex + 1);
  }
}
