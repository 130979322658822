<div style="height: 70px;">
    <app-header [hidden]="!showHeaderandFooter"></app-header>
</div>
<router-outlet></router-outlet>
<app-footer *ngIf="showGlobalFooter && showHeaderandFooter" 
[ngClass]="globalFooterOffset 
? '-footer-offset' : footerFor === 'smb' 
? '-add-margin-smb' : footerFor === 'enterprise' 
? '-add-margin-enterprise' : footerFor === 'platforms' 
? '-add-margin-platforms' : footerFor === 'payfac' 
? '-add-margin-payfac' : footerFor === 'financial-institution' 
? '-add-margin-financial-institution' : footerFor === 'independent-sales-organization' 
? '-add-margin-independent-sales-organization' : footerFor === 'independent-software-vendor' 
? '-add-margin-independent-software-vendor' : ''" mode="isSmallFooter ? null : 'primary'"></app-footer>
