<div #parent class="-api-spec-container">
    <div class="hosted-api-docs">
        <div id="apimatic-widget" style="height: 100%; width: 100%;"></div>
    </div>
    <div>
        <app-footer mode="null"></app-footer>
    </div>
</div>


