import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'card-category-product',
  templateUrl: './card-category-product.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CardCategoryProductComponent extends CardComponent {
  template = 'external';

  ngOnInit() {
    if (this.to && this.to.trim().search(/(http|https):/) !== 0) {
      this.template = 'internal';
    }
  }
}
